import React from 'react'
import './FAQ.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function FAQ() {

    const faqList = [
        {
            question: "How Can You Guarantee 5X ROIs?",
            answer: "Any agency that promises 5x ROIs without a proper understanding of the business is bluffing. At DL Media, we offer a comprehensive free audit that helps us determine whether we can confidently deliver results for you. Don't take our word for it – book your discovery call and see for yourself why we’re the right partner for your business."
        },
        {
            question: "Describe The Partnership In Detail:",
            answer: "We start with a discovery call where we learn more about your business. After, a free audit is provided to review your analytics and past campaigns in order to create a strategic plan. Next, we host a testing period with a small ad spend budget to collect data. Finally, we move into a full partnership and scale your business for the long term. You only pay for results."
        },
        {
            question: "What Is DL Media's Percent Cut?",
            answer: "We believe in providing value and building long-term partnerships with our clients. That's why we determine our commission percentage based on your profit margins – we don't want to take an unreasonable cut. Schedule a discovery call with us to learn more about the exact percentage we take."
        },
        {
            question: "What Is Performance-Based?",
            answer: "At DL Media, we're all about delivering results for our clients. That's why we don't charge any upfront fees – instead, we take a commission percentage based solely on the revenue generated from our campaigns. This ensures that we're incentivized to deliver the best possible outcomes for your business. Feeling intrigued? Book a discovery call with us today."
        }
    ]

    return (
        <div className='FAQ'>
            <section className='title_box'>
                <h1 className='title'>FAQ</h1>
                <div className='dividerTop'></div>
            </section>

            <section className='FAQ_container'>
                {faqList.map((faq) => (
                    <Link className='FAQ_colm'>
                        <section className='questionBox'>
                            <div className='icon'></div>
                            <h1 className='question'>{faq.question}</h1>
                        </section>
                        <h1 className='answer'>{faq.answer}</h1>
                    </Link>
                ))}
            </section>

            <div className='dividerBottom'></div>

        </div>
    )
}
