import React from 'react'
import './Success_Stories.css'

export default function Success_Stories() {

    const successList = [
        {
            company: "airbnb",
            ROA: "430%",
            budget: "$1.1 Million",
            revenue_num: "5.2 ",
            revenue_label: "Million"
        },
        {
            company: "fitbit",
            ROA: "1020%",
            budget: "$1.2 Million",
            revenue_num: "12.2 ",
            revenue_label: "Million"
        },
        {
            company: "twistedtea",
            ROA: "700%",
            budget: "$2.7 Million",
            revenue_num: "18.9 ",
            revenue_label: "Million"
        },
        {
            company: "LAfitness",
            ROA: "390%",
            budget: "$550k",
            revenue_num: "2.6 ",
            revenue_label: "Million"
        },
        {
            company: "tripadvisor",
            ROA: "320%",
            budget: "$420k",
            revenue_num: "1.3 ",
            revenue_label: "Million"
        },
        {
            company: "trustpilot",
            ROA: "620%",
            budget: "$990k",
            revenue_num: "6.1 ",
            revenue_label: "Million"
        },
        {
            company: "wizementoring",
            ROA: "430%",
            budget: "$180k",
            revenue_num: "774",
            revenue_label: "k"
        },
        {
            company: "urbanpods",
            ROA: "500%",
            budget: "$90k",
            revenue_num: "450",
            revenue_label: "k"
        },
        {
            company: "luxuryrealtor",
            ROA: "590%",
            budget: "$108k",
            revenue_num: "637",
            revenue_label: "k"
        },
        {
            company: "freezvon",
            ROA: "1000%",
            budget: "$57k",
            revenue_num: "570",
            revenue_label: "k"
        },
        {
            company: "corebuy",
            ROA: "390%",
            budget: "$396k",
            revenue_num: "1.6 ",
            revenue_label: "Million"
        },
        {
            company: "masterclass",
            ROA: "370%",
            budget: "$360k",
            revenue_num: "1.3 ",
            revenue_label: "Million"
        }
    ]

    return (
        <div className='Success_Stories'>

            <section className='title_box'>
                <h1 className='title'>Our Success Stories</h1>
                <div className='dividerTop'></div>
            </section>

            <section className='story_container'>
                {successList.map((success) => (
                    <section className='success_colm'>
                        <div className={`logo ${success.company}`}></div>
                        <h1 className='ROA_title'>ROAS</h1>
                        <h1 className='ROA'>{success.ROA}</h1>
                        <h1 className='budget_title'>Budget</h1>
                        <h1 className='budget'>{success.budget}</h1>
                        <h1 className='revenue_title'>Revenue Produced</h1>
                        <h1 className='revenue'>$<span className={`amount ${success.company}`}>{success.revenue_num}</span>{success.revenue_label}</h1>
                    </section>
                ))}

            </section>
        </div>
    )
}
