import React from 'react'
import './Different_Info.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function Different_Info() {
  return (
    <div className='Different_Info'>

        <section className='different_title_box'>
            <h1 className='title'>Why Are We <span className='highlight'>Different?</span></h1>
            <h1 className='subTitle'>At DL Media, We <span className='bold'>Stand Out</span> From The Crowd By Delivering Results That Speak For Themselves. </h1>
        </section>

        <section className='infoContiner left'>
            <div className='icon'></div>
            <section className='textbox'>
                <h1 className='title'>Pay Only For <span className='highlight'>Results</span></h1>
                <h1 className='subTitle'>We Charge <span className='bold'>No Upfront Fees</span>. Instead, We Take A Commission Percentage From The Results We Achieve. </h1>
                <section className='btnBox'>
                    <Link to="/freeaudit" className="CTA">Get Results Now</Link>
                    <Link to="/casestudies" className="alternate">Not Sure Yet?</Link>
                </section>
            </section>
        </section>

        <section className='infoContiner right'>
            <div className='icon'></div>
            <section className='textbox'>
            <h1 className='title'>Our Client <span className='highlight'>Process</span></h1>
            <h1 className='subTitle'>We Take On Clients Only When We're <span className='bold'>100%</span> Confident We Can Deliver Results. </h1>
                <section className='btnBox'>
                    <Link to="/freeaudit" className="CTA">Join Our Team</Link>
                    <Link to="/casestudies" className="alternate">Still Unsure?</Link>
                </section>
            </section>
        </section>
        
        <div className='resultsIcon'></div>
        <div className='processIcon'></div>


    </div>
  )
}
