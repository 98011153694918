import React from 'react'
import './About_Us_Header.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function About_Us_Header() {

    const dataList = [
        {
            desc:"Average Return Of 5x+ For Our Clients"
        },
        {
            desc:"Performance-Based Agency:  Pay ONLY for RESULTS"
        },
        {
            desc:"Stop Wasting Time and Money On Ineffective Ad Campaigns"
        }
    ]

  return (
    <div className='About_Us_Header'>
        <h1 className='title'>Our <span className='select'>Team</span></h1>
        <h1 className='subTitle'>At DL Media, we're breaking the mold and disrupting the industry with our <span className='select'>young and dynamic team</span>. Our clients may be surprised to learn that our founders are still in school - but that's because we believe in staying ahead of the curve and embracing fresh perspectives. We're not your typical stuffy corporate agency - our team is made up of experts under 40 who live and breathe digital marketing. So if you want to stay on top of the game and produce killer online results, come join the DL Media family!</h1>
        <section className='btnBox'>
            {dataList.map((data) => (
                <div className='item'>
                    <h1 className='desc'>{data.desc}</h1>
                    <div className='iconBox'>
                        <div className='icon'></div>
                    </div>
                </div>
            ))}
        </section>
    </div>
  )
}
