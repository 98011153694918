import React from 'react'
import './Sucess_Steps.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function Sucess_Steps() {

    const stepsList = [
        {
            picId: "discovery",
            title: "1). Discovery Call",
            desc: "Have A Discovery Call To Discuss Your Project Requirements."
        },
        {
            picId: "strat_audit",
            title: "2). Strategy Audit",
            desc: "Study Your Business Analytics To Understand Areas Of Growth."
        },
        {
            picId: "test_period",
            title: "3). Testing Period",
            desc: "We Will Collect Data From A Test Ad Campaign Over The Span Of 2 Weeks."
        },
        {
            picId: "optmization",
            title: "4). Optimization",
            desc: "Data From Our 2-week Test Period Will Allow Us To Fix And Grow Profits."
        },
        {
            picId: "project_report",
            title: "5). Project Success Report",
            desc: "We Will Have A Call To Set Up And Discuss Of Success Project Plan."
        },
        {
            picId: "in_touch",
            title: "6). Staying In Touch",
            desc: "We Will Create Weekly Reports For You And Plan Ongoing Strategy Calls."
        }
    ]

    return (
        <div className='Sucess_Steps'>

            <section className='headerContainer'>

                <section className='titleBox'>
                    <h1 className='hero_title'>Our 6 Steps For <span className='select'>Success</span></h1>
                    <h3 className='hero_desc'>Walk With Us Through Our 6-Step Process To Learn More About Your Business And Grow Exceptional Results.</h3>
                    <Link to="Free Audit" className="CTA">
                        <h1>Get Started Today</h1>
                    </Link>
                </section>

                <div className='icon'></div>

            </section>

            <section className='steps_container'>
                {stepsList.map((step) => (
                    <div className='step_colm'>
                        <div className='iconBox'>
                            <div className={`icon ${step.picId}`}></div>
                        </div>
                        <section className='textbox'>
                            <h1 className='title'>{step.title}</h1>
                            <h1 className='subtitle'>{step.desc}</h1>
                        </section>

                    </div>
                ))}
            </section>

        </div>
    )
}
