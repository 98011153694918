import React from 'react'
import './Our_Clients.css'

export default function Our_Clients() {

    const clientList = [
        {
            name: "Airbnb"
        },
        {
            name: "Tripadvisor"
        },
        {
            name: "Fitbit"
        },
        {
            name: "TwistedTea"
        },
        {
            name: "Trustpilot"
        },
        {
            name: "LaFitness"
        }
    ]

  return (
    <div className='Our_Clients'>
        <section className='title_box'>
            <h1 className='title'>Our Clients</h1>
            <div className='dividerTop'></div>
        </section>
        <section className='clients_container'>
            {clientList.map((client) => (
                <div className={`client ${client.name}`}></div>
            ))}
        </section>
        <div className='dividerBottom'></div>
    </div>
  )
}
