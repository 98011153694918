import React from 'react'
import './Meet_Founders.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function Meet_Founders() {
  return (
    <div className='Meet_Founders'>

        <section className='textBox'>
            <h1 className='title'>Meet Our <span className='select'>Founders</span></h1>
            <h1 className='desc'>David and Luke founded DL Media because they saw a growing need for effective digital advertising. Unlike other agencies that prioritize profits above all else, our top priority is to <span className='select'>provide value to our clients</span>. We believe that by delivering real value, financial success naturally follows. That's why we aim to build long-term partnerships with our clients, based on mutual trust and shared goals. With DL Media, you can expect a partner who is committed to your success, not just in the short-term, but for years to come.</h1>
            <a  href="mailto: business@dlmedia.org" className="CTA_button">
            <h1 className='CTA_name'>Get In Contact</h1>
            </a>
        </section>

        <section className='foundersBox'>

            <div className='founderColm'>
                <div className='pic luke'></div>
                <h1 className='name'>Luke Vorbau</h1>
                <h1 className='company_title'>CTO</h1>
            </div>

            <div className='founderColm'>
                <div className='pic david'></div>
                <h1 className='name'>David Stolyar</h1>
                <h1 className='company_title'>CEO</h1>
            </div>

        </section>

    </div>
  )
}
