import React from 'react'
import './Accomplishments.css'

export default function Accomplishments() {

  return (
    <div className='Accomplishments'>
        <section className='title_box'>
            <h1 className='title'>Our Accomplishments</h1>
            <div className='dividerTop'></div>
        </section>
        <section className='accomplishment_container'>

           <section className='accomplishment_colm revenue'>
            <h1 className='subTitle'>Total Revenue Generated</h1>
            <h1 className='Title'>$<span className='select'>82.3</span> Million</h1>
           </section>

           <section className='accomplishment_colm spend'>
            <h1 className='subTitle'>Total Ad Spend</h1>
            <h1 className='Title'>$<span className='select'>19.4</span> Million</h1>
           </section>

           <section className='accomplishment_colm clients'>
            <h1 className='subTitle'>Clients Helped</h1>
            <h1 className='Title'><span className='select'>31</span> Clients</h1>
           </section>

        </section>
        <div className='dividerBottom'></div>
    </div>
  )
}
