import React from 'react'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import './Hero_Header.css'

export default function Hero_Header() {
    return (
        <div className='Case_Hero_Header'>
            <section className='textbox'>
                <h1 className='hero_title'><span className='select'>100%</span> Success Rate. <span className='select'>500%</span> Average Returns. Impressed Yet?</h1>
                <h3 className='hero_desc'>DL Media is proud to say that we have an exceptional amount of client <span className='highlight'>success stories</span> under our belt. we work relentlessly to achieve the best results for our clients and can guarantee prosperous results for you as well. Ready to join the club?</h3>
                <Link to="/freeaudit" className="CTA">
                    <h1>Scale Your Business Today</h1>
                </Link>
            </section>
            <section className='hero_img'></section>
        </div>
    )
}
