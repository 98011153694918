import React from 'react'
import './Case_Studies/Case_Studies.css'
import Hero_Header from './Case_Studies/Hero_Header'
import WhiteSpace from './Home/WhiteSpace'

import NavBar from './NavBar/NavBar'
import Accomplishments from './Case_Studies/Accomplishments'
import Success_Stories from './Case_Studies/Success_Stories'
import Footer from './Footer/Footer'

export default function Case_Studies() {
  return (
    <div className='Case_Studies'>
        <NavBar/>
        <WhiteSpace width="10%" height="2rem"/>
        <Hero_Header/>
        <WhiteSpace width="10%" height="5rem"/>
        <Accomplishments/>
        {/* <WhiteSpace width="10%" height="2rem"/> */}
        <Success_Stories/>
        <WhiteSpace width="10%" height="25rem"/>
        <Footer/>
    </div>
  )
}
