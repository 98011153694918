import React from 'react'
import './Hero_Header.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function Hero_Header() {
  return (
    <div className='Hero_Header'>
        <section className='textbox'>
            <h1 className='hero_title'>Maximize <span className='select'>ROI</span>, Scale Your Business, and Blow Up Your <span className='select'>Sales</span>!</h1>
            <h3 className='hero_desc'>At DL Media, we specialize in one thing and one thing only: <span className='highlight'>E-commerce Advertising</span>. Our focused niche has allowed us to develop a great level of expertise, enabling us to deliver exceptional results for our clients.</h3>
            <section className='btn_box'>
                <Link to="/freeaudit" className="CTA">
                    <h1>Scale Your Business Today</h1>
                </Link>
                <Link to="/aboutus" className="alternate">
                    <h1>Speak To Our Team</h1>
                </Link>
            </section>
            <section className='check_list'>

                <section className='check_colm'>
                    <div className='check_img'></div>
                    <h1 className='check_text'>Average Return Of 5x+ For Our Clients</h1>
                </section>

                <section className='check_colm'>
                    <div className='check_img'></div>
                    <h1 className='check_text'>Performance-Based Agency: Pay ONLY For RESULTS</h1>
                </section>

                <section className='check_colm'>
                    <div className='check_img'></div>
                    <h1 className='check_text'>Stop Wasting Time and Money on Ineffective Ad Campaigns</h1>
                </section>
                
            </section>
        </section>
        <section className='hero_img'></section>

        <div className='heroIcon'></div>
    </div>
  )
}
