import React from 'react'
import './NavBar.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import '../fonts/styles.css'

export default function NavBar() {
  return (
    <div className='NavBar'>
        <Link to="/" className='app_logo'></Link>
        <section className='pages_container'>
        <Link to="/" className='page_button'>
            <h1>Home</h1>
        </Link>
        <Link to="/casestudies" className='page_button'>
            <h1>Case Studies</h1>
        </Link>
        <Link to="/aboutus" className='page_button'>
            <h1>About Us</h1>
        </Link>
        </section>
        <Link to="/freeaudit" className='CTA_button'>
            <h1>Free Audit</h1>
        </Link>
    </div>
  )
}
