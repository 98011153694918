import React, { useState, useRef, useEffect, Component } from 'react'
import ReactDOM from "react-dom";
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

//imported font styles
import './fonts/styles.css'

//import Navbar css
import './NavBar/NavBar.css'

//import databases

import './locomotive_scroll/locomotive_scroll.css'

// imported components
import Home from './Home/Home';
import Case_Studies from './Case_Studies';
import About_Us from './About_Us';
import Free_Audit from './Free_Audit';

function App() {

  return (
    <Router>
      <div data-scroll-container className="app">
      <Route data-scroll-section path="/" exact component={Home} />
      <Route data-scroll-section path="/casestudies" exact component={Case_Studies} />
      <Route data-scroll-section path="/aboutus" exact component={About_Us} />
      <Route data-scroll-section path="/freeaudit" exact component={Free_Audit} />
      </div>
    </Router>
  );
}

export default App;
