import React from 'react'
import './Home.css'
import WhiteSpace from './WhiteSpace';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import Hero_Header from './Hero_Header';
import Our_Clients from './Our_Clients';
import Testimonials from './Testimonials';
import Different_Info from './Different_Info';
import FAQ from './FAQ';
import Sucess_Steps from './Sucess_Steps';
import CTA_Header from './CTA_Header';
import Footer from '../Footer/Footer';

//import comps
import NavBar from '../NavBar/NavBar';

export default function Home() {

    return (
        <div className='Home'>
            <NavBar/>
            <WhiteSpace width="10%" height="2em"/>
           <Hero_Header/>
           <WhiteSpace width="10%" height="4rem"/>
           <Our_Clients/>
           {/* <WhiteSpace width="10%" height="4em"/> */}
           <Testimonials/>
           {/* <WhiteSpace width="10%" height="4rem"/> */}
           <Different_Info/>
           <WhiteSpace width="10%" height="8rem"/>
            <FAQ/>
            {/* <WhiteSpace width="10%" height="8rem"/> */}
            <Sucess_Steps/>
            {/* <WhiteSpace width="10%" height="4rem"/> */}
            <CTA_Header/>
            <WhiteSpace width="10%" height="4rem"/>
            <Footer/>
        </div>

    )
}
