import React from 'react'
import './About_Us/About_Us.css'

import NavBar from './NavBar/NavBar'

import WhiteSpace from './Home/WhiteSpace'
import About_Us_Header from './About_Us/About_Us_Header'
import Meet_Founders from './About_Us/Meet_Founders'
import Footer from './Footer/Footer'

export default function About_Us() {
  return (
    <div className='About_Us'>
        <NavBar/>
        <WhiteSpace width="10%" height="5rem"/>
        <About_Us_Header/>
        <WhiteSpace width="10%" height="10rem"/>
        <Meet_Founders/>
        <WhiteSpace width="10%" height="10rem"/>
        <Footer/>
    </div>
  )
}
