import React from 'react'
import './CTA_Header.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function CTA_Header() {
    return (
        <div className='CTA_Header'>

            <div className='icon'></div>

            <section className='titleBox'>
                <h1 className='hero_title'>Start <span>Growing</span> Your Business Today</h1>
                <h3 className='hero_desc'>Lets Take A Journey Of <span>Success</span> For Your Company Together. It All Begins With An Audit.</h3>
                <Link to="/freeaudit" className="CTA_button">
                    <h1>Schedule Your Free Audit</h1>
                </Link>
            </section>

            <div className='CTA_icon'></div>

        </div>
    )
}
