import React from 'react'
import './Free_Audit/Free_Audit.css'
import NavBar from './NavBar/NavBar'
import WhiteSpace from './Home/WhiteSpace'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import Footer from './Footer/Footer';
import './locomotive_scroll/locomotive_scroll.css'

export default function Free_Audit() {

    const dataList = [
        {
            title:"Discovery Call:",
            desc:"Learn About Your Goals, Profit Margins, Etc.",
            num:"1"
        },
        {
            title:"Gathering Data:",
            desc:"Review Analytics To See If We’re A Good Fit.",
            num:"2"
        },
        {
            title:"Strategy Breakdown:",
            desc:"Call To Determine If We Can Work Together.",
            num:"3"
        },
        {
            title:"Testing Period:",
            desc:"We Show Our Skills With A Small Budget.",
            num:"4"
        },
        {
            title:"Partnership:",
            desc:"Once We Show Initial Success, The Budget Increases.",
            num:"5"
        },
        {
            title:"Stay In Touch:",
            desc:"We Achieve 5x ROAS And Set Up Weekly Calls.",
            num:"6"
        }
    ]
    
  return (
    <div className='Free_Audit'>
       <NavBar/>
        <WhiteSpace width="10%" height="5rem"/>
        <h1 className='title'>Start Your <span className='select'>Free Audit</span></h1>
        <WhiteSpace width="10%" height="3rem"/>
        <section data-scroll-section className='steps_container'>
            {dataList.map((item) => (
                <div className='dataItem'>
                    <h1 className='name'><span className='bold'>{item.title}</span> {item.desc}</h1>
                    <div className='iconBox'>
                        <h1 className='number'>{item.num}</h1>
                    </div>
                </div>
            ))}
        </section>
        <WhiteSpace width="10%" height="3rem"/>
        <a href={`https://calendly.com/dlmedia_/30min?back=1&month=2023-05`} target="_blank" className="discovery_btn">
            <h1 className='name'>Schedule Your Discovery Call</h1>
        </a>
        <WhiteSpace width="10%" height="10rem"/>
        <Footer/>
    </div>
  )
}
