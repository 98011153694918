import React from 'react'
import './Testimonials.css'

export default function Testimonials() {

    const testimonialList = [
        {
            quote:"Having worked at TripAdvisor for several years, I understand the importance of finding a partner that can help us navigate the ever-changing travel industry and stand out in a crowded market. That's why DL Media caught our attention...",
            author:"COO, TripAdvisor",
            authorId:"COO"
        },
        {
            quote:"I discovered DL Media thanks to a recommendation from a Fortune 500 colleague. While we have worked with numerous agencies in the past, what set DL Media apart was their commitment to providing value and fostering long-term relationships...",
            author:"CMO, AirBnb",
            authorId:"CMO"
        },
        {
            quote:"As the VP of Marketing at Masterclass, I'm constantly seeking out partners who can help us innovate and stay ahead of the curve. That's why I was thrilled when DL Media reached out to us. What impressed me the most about working with them was...",
            author:"VP of Marketing, Masterclass",
            authorId:"VP"
        }
    ]

  return (
    <div className='Testimonials'>
    <section className='title_box'>
        <h1 className='title'>Testimonials</h1>
        <div className='dividerTop'></div>
    </section>
    <section className='testimonial_container'>
        {testimonialList.map((testimonial) => (
            <div className={`testimonialBox ${testimonial.authorId}`}>
                <h1 className='author'>{testimonial.author}</h1>
                <h1 className='quote'>{testimonial.quote}</h1>
            </div>
        ))}
    </section>
    <div className='dividerBottom'></div>
</div>
  )
}
