import React from 'react'
import './Footer.css'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className='Footer'>

            <section className='textbox'>
                <div className='company_logo'></div>
                <h1 className='desc'>Maximize ROI, Scale Your Business, and Blow Up Your Sales!</h1>
            </section>

            <section className='data_container'>

                <section className='mailBox'>
                    <div className='logo'></div>
                    <a href="mailto: business@dlmedia.org" className='dataText email'>business@dlmedia.org</a>
                </section>

                <Link className="dataText">Terms Of Service</Link>
                <Link className="dataText">Privacy Policy</Link>
                <Link className="dataText inc">@ DL Media, Inc.</Link>

            </section>
        </div>
    )
}
